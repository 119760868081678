.subscriptionLogo {
  padding-top: 31px;
  display: flex;
  justify-content: center;

  & > img {
    width: 140px;
  }
}

.subscriptionTitle {
  padding-top: 49px;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  color: #1D2434;
  text-align: center;
}

.subscriptionSummary {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1D2434;
  padding-top: 8px;
  text-align: center;
}

.subscriptionOptions {
  padding-top: 20px;
}

.subscriptionButtonGroup {
  border: 1px solid #DDDDE4;
  border-radius: 8px;
  padding: 2px;
  display: flex;
  width: max-content;
  margin: 0 auto;
}

.subscriptionButton {
  padding: 6px 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #333333;
  cursor: pointer;
}

.subscriptionButtonActive {
  background: #333333;
  border-radius: 6px;
  color: #FFFFFF;
}

.subscriptionSaveUp {
  padding-top: 19px;
  position: relative;
  width: max-content;
  margin: 0 auto;
  padding-right: 25px;

  & > img {
    width: 20px;
    position: absolute;
    right: 0;
    top: 12px;
  }
}

.planContainer {
  padding: 24px 48px 48px;
  display: flex;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0
  }

  .plans {
    width: calc(100% / 4);
    margin: 0 12px;
    box-shadow: 0px 2px 6px rgba(86, 94, 237, 0.2), 0px 10px 20px rgba(30, 38, 198, 0.12);
    border-radius: 18px;
    padding: 48px 32px 78px;
    min-width: 300px;

    .plansTitle {
      color: #000000;
      font-weight: 800;
      font-size: 24px;
      line-height: 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .plansMost {
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        padding: 3px 6px;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        margin-left: 8px;
      }
    }

    .plansPrice {
      text-align: center;

      .plansOldPrice {
        color: #999999;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-decoration: line-through;
      }

      .plansNewPrice {
        color: #333333;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .plansText {
      color: #666666;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }

    .plansLetTask {
      padding-top: 32px;
      color: #333333;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      & > img {
        padding-left: 4px;
        width: 20px;
      }
    }

    .plansButton {
      width: 100%;
      padding: 14px;
      background: linear-gradient(91.67deg, #565EED 0%, #2E37D1 99.88%);
      border-radius: 8px;
      color: #FFFFFF;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-top: 40px;
    }

    .buttonDisable {
      background: #DDDDE4;
      cursor: unset !important;
    }

    .plansSummary {
      padding-top: 24px;
      color: #1D2434;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }

    .plansTopBox {
      min-height: 207px;
      border-bottom: 1px solid rgba(46, 49, 94, 0.08);
      padding: 24px 0;
    }

    .unsetHeight {
      min-height: unset;
    }

    .plansInclude {
      padding: 24px 0;
      color: #999999;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
    }

    .plansOptions {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      padding-bottom: 20px;

      & > img {
        width: 20px;
        padding-right: 8px;
      }
    }
  }
}

@media (max-width: 768px) {
  .subscriptionContainer {
    padding: 0 16px;
  }

  .subscriptionLogo {
    padding-top: 24px;
  }

  .subscriptionTitle {
    padding-top: 32px;
    font-size: 20px;
  }

  .subscriptionSaveUp {
    display: none;
  }

  .planContainer {
    display: block;
    padding: 22px 0;

    .plans {
      width: 100%;
      margin: 0 0 12px;
      padding: 32px 16px 16px;

      .plansOptions {
        padding: 0 15px 20px;
      }
    }
  }
}

.modal {
  padding: 0;
  margin: 0;
  min-width: 580px;
  max-width: 640px;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%), 0px 0px 20px rgb(0 0 0 / 15%);
  
  @media (max-width: 768px) {
    min-width: 320px;
    max-width: 100%;
    margin: 16px;
  }
}

.modalContent {
  background: white;
  padding: 32px;
  border-radius: 8px;
  width: 100%;
  text-align: center;
}

.modalTitle {
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  text-align: center;
  color: #333333;
}

.modalDescription {
  margin-bottom: 24px;
  color: #4A4E57;
}

.modalButtons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.cancelButton {
  color: #333333;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

.limitBlocks {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  justify-content: center;
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    align-items: center;
  }
}

.limitBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  background: #F7F7F7;
  border-radius: 8px;
  text-align: center;
  min-width: 180px;
  max-width: 200px;
  
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    flex-direction: column;
    text-align: center;
    padding: 16px;
    gap: 12px;
  }
}

.iconWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 8px;
  margin-bottom: 12px;
  flex-shrink: 0;
  
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
}

.blockContent {
  width: 100%;
  
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.blockTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #8C8C8C;
  margin-bottom: 8px;
  
  @media (max-width: 768px) {
    justify-content: center;
    margin-bottom: 8px;
  }
}

.blockInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.usageAmount {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #E24F4F;
}

.arrow {
  color: #8C8C8C;
  margin: 0 8px;
}

.newLimit {
  font-weight: 800;
  font-size: 20px;
  line-height: 24px;
  color: #333333;
}

@media (max-width: 768px) {
  .modalContent {
    padding: 24px 16px;
    text-align: center;
  }
  
  .modalTitle {
    font-size: 20px;
    line-height: 28px;
  }
  
  .modalDescription {
    font-size: 14px;
    line-height: 20px;
  }
}
